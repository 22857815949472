import React from "react";
//import { Link } from "gatsby";
//import { PageBannerData } from "@/data";

const PageBanner = ({ title, name, image, data }) => {
  //const {abouturl, contacturl, serviceurl} = data;
  //console.log(abouturl, contacturl, serviceurl);
  return (
    <section>
      
        <section className="pageBanner" style={{ backgroundImage: `url(${image})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner_content text-center">
                  <h4>
                    {/* {PageBannerData.map(({url}, index) => ( */}
                      <a href="/">home</a> - {name} {/*<Link to='/service'></Link> */}
                    {/* ))} */}
                    
                  </h4>
                  <h2>{title}</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
 
    </section>
  );
};

export default PageBanner;
