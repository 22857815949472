import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-8 col-md-6">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
                As a software development company that aims to help you with our commitment and dedication, 
                we deliver reliable and effective technology solutions to you that fulfill all your needs.
              </p>
            </aside>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3">
            <aside className="widget company_widgets">
              <h3 className="widget_title">Company</h3>
              <p>
                <a href="/about">About Us</a>
              </p>
              <p>
                <a href="/service">Services</a> 
              </p>
              <p>
                <a href="/contact">Contact Us</a>
              </p>
            </aside>
          </div>
          <div className="col-lg-2 col-sm-3 col-md-3">
            <aside className="widget company_widgets">
              <h3 className="widget_title">Services</h3>
              <p>
                <a href="/service-details">Custom Software</a>
              </p>
              <p>
                <a href="/service-details-2">DevOps</a> 
              </p>
              <p>
                <a href="/service-details-3">UI/UX Designing</a>
              </p>
              <p>
                <a href="/service-details-4">Enterprise Collaboration</a> 
              </p>
              <p>
                <a href="/service-details-5">Software Consultancy</a>
              </p>
            </aside>
          </div>
          <div className="col-lg-2 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                <i className= "fa fa-map-marker" /*style={{paddingRight:8+'px'}}*/ > </i> Coming Soon!
                {/* <br />
                Pakistan */}
              </p>
              <p><i className= "fa fa-phone"></i> 666 888 000</p>
              <p>
                <i className="fa fa-envelope"></i> kloudxel@gmail.com
              </p>
            </aside>
          </div>
          <div className="col-lg-2 col-sm-4 col-md-4">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/cloud-excel-b1733723b/" target="_blank">
                    <i className="fa fa-linkedin"></i>LinkedIn
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>Twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>Youtube
                  </a>
                </li> */}
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} {/* by */}{" "}
              {/* <a href="#">Layerdrops.com</a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
